import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { CommonUtil } from '../util/common-util';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public Sliders: any;
  public url: string;

  constructor(private http: HttpClient,
    private config: ServiceConfigs) { }


  // getCustomerDeliveryType
public getCustomerDeliveryType(): Observable<any> {
    this.url=(environment.mock)? this.config.GET_CUSTOMER_DELIVERY_TYPE.mockUrl: this.config.GET_CUSTOMER_DELIVERY_TYPE.url;

    let queryParams = new HttpParams();
    if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
      queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
    }
    
    return this.http.get<any>(this.url, { params: queryParams }).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }

    // updateCustomerDeliveryType
    public updateDeliveryType(requestParams: any): Observable<any> {
    this.url=(environment.mock)? this.config.UPDATE_CUSTOMER_DELIVERY_TYPE.mockUrl: this.config.UPDATE_CUSTOMER_DELIVERY_TYPE.url;

    return this.http.post<any>(this.url, requestParams).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  }
  
    
}

