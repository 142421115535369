import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { Notification } from '../classes/notification';
import { CommonUtil } from '../util/common-util';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';



@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public url: string;

  constructor(private http: HttpClient,
    private config: ServiceConfigs,
    private toastrService: ToastrService,
    private translateService: TranslateService) { }

  
// My Address list 
public getNotificationsList(): Observable<Notification[]> {
  this.url=(environment.mock)? this.config.GET_NOTIFICATIONS_LIST.mockUrl: this.config.GET_NOTIFICATIONS_LIST.url;
  
  let queryParams = new HttpParams();
  queryParams = queryParams.append("id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')}`);
  queryParams = queryParams.append("app_type", 'WebAndCustomerApp');
  return this.http.get<Notification>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

public updateNotificationStatus(notificationRequest: any): Observable<any> {
  this.url = this.config.UPDATE_NOTIFICATIONS_STATUS.url;
  return this.http.post<any>(`${this.url}`,
  notificationRequest).pipe(
    catchError((httpErrorResponse: HttpErrorResponse) => {
      if (httpErrorResponse.error.status !== 404)
          this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

      return of([]);
  })
  );
}
  
}
