import { Component, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogueComponent } from '../confrimation-dialogue/confrimation-dialogue.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopUpComponent {
  formData: string = '';
  reactiveForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<PopUpComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any, private builder: FormBuilder) { }

  create() {
  if(!this.data.wishList.some((wishData) => wishData.group_name == this.formData) && this.formData.trim())
    this.dialogRef.close({ 'result': this.formData });
  else if(!this.formData.trim())
    this.toastrService.error('Wishlist group name is empty', 'Error');
  else
    this.toastrService.error('Group Name already exist', 'Error');  
  }

  cancel() {
    this.dialogRef.close({ 'result': 'Cancel' });
  }
  ngOnInit() {
    this.reactiveForm = this.builder.group({
      collection: [null, Validators.required]
    });
    console.log('wish list itens', this.data.wishList);
    }
}

