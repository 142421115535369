import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CUSTOMER_PORTAL_CONSTANT } from "src/app/constant/constant";
import { AppSettings } from "../classes/user";

@Injectable({
    providedIn: 'root'
  })
  export class GlobalService {
    public termsAndConditionsReadMore: any;
    public termsAndConditionsHeading: any;
    public previousAppRouterUrl: string = '';
    public currentLanguage: string = CUSTOMER_PORTAL_CONSTANT.DEFAULT_LANGUAGE;
    public language$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public megaMenu$: BehaviorSubject<any> = new BehaviorSubject<any>('');

    public myAddressSelectedAreaType: string = '';
    public customerDeliveryAddress: any;
    
      constructor(private translateService: TranslateService,) {

    }

    public translate(lang?: string): void {
      this.currentLanguage = lang;
      this.translateService.use(lang);
      this.language$.next(lang);
      let appSettings: AppSettings = {layout:'rtl', language: ''};
      if(localStorage.getItem('appSettings') !== null) {
        appSettings = JSON.parse(localStorage.getItem('appSettings'));
        appSettings.language = lang;
      } else {
        appSettings['language'] = lang;
      }
      localStorage.setItem('appSettings', JSON.stringify(appSettings));
  }
  public getCurrentLanguage(): string { return this.currentLanguage }
  public headerIntoBodyState$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // Header address dropdown addnew address
  public headerIntoAddNewAddessState$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public showAddressState$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  //Shopping cart count 
  public cartCountState$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // Default Delivery Address Switching from HeaderSection 
  public headerSectionDefaultAddressState$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // Default Delivery Address Switching from MyAddressSection 
  public myAdderessSectionDefaultAddressState$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // Notification count
  public notificationCountState$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public editAddressSection$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public setSearchCategoryState(state: string): void {
    this.headerIntoBodyState$.next(state);
  }

  public getSearchCategoryState():  Observable<string> {
    return this.headerIntoBodyState$.asObservable();
  }

  public setAddNewAddressState(state: string): void {
    this.headerIntoAddNewAddessState$.next(state);
  }

  public getAddNewAddressState():  Observable<string> {
    return this.headerIntoAddNewAddessState$.asObservable();
  }

  public setShowAddressState(state: string): void {
    this.showAddressState$.next(state);
  }

  public getShowAddressState():  Observable<string> {
    return this.showAddressState$.asObservable();
  }

  public setCartCountState(state: string): void {
    this.cartCountState$.next(state);
  }

  public getCartCountState():  Observable<string> {
    return this.cartCountState$.asObservable();
  }

  public setNotificationCountState(state: string): void {
    this.notificationCountState$.next(state);
  }

  public getNotificationCountState(): Observable<any> {
    return this.notificationCountState$.asObservable();
  }

  // Dynamic Default Address Switching
  public setHeaderSectionDefaultAddressState(state: string): void {
    this.headerSectionDefaultAddressState$.next(state);
  }

  public getHeaderSectionDefaultAddressState():  Observable<string> {
    return this.headerSectionDefaultAddressState$.asObservable();
  }

  // Dynamic Default Address Switching
  public setMyAddressSectionDefaultAddressState(state: string): void {
    this.myAdderessSectionDefaultAddressState$.next(state);
  }

  public getMyAddressSectionDefaultAddressState():  Observable<string> {
    return this.myAdderessSectionDefaultAddressState$.asObservable();
  }

  //Get address details by Id
  public setEditAddressDetails(state: any): void {
    this.editAddressSection$.next(state)
  }

  public getEditAddressDetails(): Observable<any> {
    return this.editAddressSection$.asObservable();
  }

  public setMegamenuDetails(menuItem: any) {
    this.megaMenu$.next(menuItem);
  }

  public getMegamenuDetails(): Observable<any> {
    return this.megaMenu$.asObservable();
  }

  }