import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderService } from '../shared/services/order.service';
import { environment } from 'src/environments/environment';
declare const Checkout: any;
@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.scss']
})
export class PaymentGatewayComponent implements OnInit {
  paymentSessionDetails: object;
  public response = '';
  myScriptElement!: HTMLScriptElement;
  constructor(private orderService: OrderService, private http: HttpClient, private spinner: NgxSpinnerService, private route: ActivatedRoute) {

    this.myScriptElement = document.createElement("script");
    this.myScriptElement.src = environment.payment_url + "/static/checkout/checkout.min.js";
    document.body.appendChild(this.myScriptElement);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params)
      let userDetails = {
        access_token:  params['access_token'],
        customer_id: Number(params['customer_id']),
        store_id: Number(params['store_id']),
        token_type: "Bearer",
        username: params['username']
      }
      localStorage.setItem('currentUser', JSON.stringify(userDetails))
      this.spinner.show();
      let requestPayload;
      requestPayload = {
        "apiOperation": "INITIATE_CHECKOUT",
        "interaction": {
          "returnUrl": 'https://' + window.location.host + '/shop/payment-status',
          "operation": "PURCHASE",
          "merchant": {
            "name": "Megamart",
            "url": 'https://' + window.location.host,
            "logo": "https://qa.megamart.qa/assets/images/logos/header-logo.svg"
          },
          "displayControl": {
            "billingAddress": "MANDATORY",
            "customerEmail": "MANDATORY"
          },
          "timeout": 1800,
          "timeoutUrl": 'https://' + window.location.host + '/shop/payment-status?status=timeout',
          "cancelUrl": 'https://' + window.location.host + '/shop/payment-status?status=cancelled',
          "style": {
            "accentColor": "#30cbe3"
          }
        },
        "order": {
          "amount": params['amount'],
          "currency": params['currency'],
          "description": "Payment",
          "id": params['orderId']
        }
      }
      this.orderService.getSessionId(requestPayload).subscribe((result: any) => {
        let response = result.data;
        response.order_id = params['orderId'];
        response.grandTotal = params['amount'];
        localStorage.setItem('payment', JSON.stringify(response))
        if (response && response.session?.id) {
          Checkout.configure({
            session: {
              id: response.session?.id
            }
          });
          setTimeout(() => {
            // return
            this.spinner.hide();
            Checkout.showPaymentPage();
          }, 1000);
        }
      });
    })

  }

}

