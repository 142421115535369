<div class="d-flex justify-content-around">

<!-- saved from url=(0030)https://qpay.megamart.qa/qpay/ -->
 <div>
   <form hidden ngNoForm [action]="actionType" method="POST" name="redirectForm"
      #paymentFormRef>
      <div style="border:1px solid ; padding: 5px 5px 5px 5px; width: max-content;">
         <table>
            <tbody>
               <tr>
                  <td> <label>Action</label></td>
                  <td><input type="text" name="Action" [value]="paymentForm.Action"><br></td>
               </tr>
               <tr>
                  <td><label>Amount</label></td>
                  <td><input type="text" name="Amount" [value]="paymentForm.Amount"> <br></td>
               </tr>
               <tr>
                  <td><label>Bank Id</label></td>
                  <td><input type="text" name="BankID" [value]="paymentForm.BankID"><br></td>
               </tr>
               <tr>
                  <td><label>Currency</label></td>
                  <td><input type="text" name="CurrencyCode" [value]="paymentForm.CurrencyCode"><br></td>
               </tr>
               <tr>
                  <td><label>ExtraFields</label></td>
                  <td><input type="text" name="ExtraFields_f14" [value]="paymentForm.ExtraFields_f14"><br></td>
               </tr>
               <tr>
                  <td> <label>Lang</label></td>
                  <td><input type="text" name="Lang" [value]="paymentForm.Lang"><br></td>
               </tr>
               <tr>
                  <td><label>MerchantID</label></td>
                  <td><input type="text" name="MerchantID" [value]="paymentForm.MerchantID"><br></td>
               </tr>
   
               <tr>
                  <td><label>MerchantModuleSessionID</label></td>
                  <td><input type="text" name="MerchantModuleSessionID" [value]="paymentForm.MerchantModuleSessionID"><br></td>
               </tr>
   
   
               <tr>
                  <td><label>NationalID</label></td>
                  <td><input type="text" name="NationalID" [value]="paymentForm.NationalID"><br></td>
               </tr>
               <tr>
                  <td><label>PUN</label></td>
                  <td><input type="text" name="PUN" [value]="paymentForm.PUN"><br> </td>
               </tr>
               <tr>
                  <td><label>PaymentDescription</label></td>
                  <td><input type="text" name="PaymentDescription" [value]="paymentForm.PaymentDescription"><br></td>
               </tr>
               <tr>
                  <td><label>Quantity</label></td>
                  <td><input type="text" name="Quantity" [value]="paymentForm.Quantity"><br></td>
               </tr>
               <tr>
                  <td><label>TransactionRequestDate</label></td>
                  <td><input type="text" name="TransactionRequestDate" [value]="paymentForm.TransactionRequestDate"><br> </td>
               </tr>
               <tr>
                  <td><label>SecureHash</label> </td>
                  <td><input type="textarea" name="SecureHash"
                        [value]="paymentForm.SecureHash"><br></td>
               </tr>
   
   
               <tr>
                  <td colspan="2"><input type="submit" [value]="paymentForm.Submit"></td>
               </tr>
            </tbody>
         </table>
   
      </div>
   </form>

</div>
</div>