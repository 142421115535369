import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { InstantErrorPopupComponent } from 'src/app/pages/instant-error-popup/instant-error-popup.component';
import { WishlistPopupComponent } from 'src/app/pages/wishlist-popup/wishlist-popup.component';
import { AddToCart } from 'src/app/shared/classes/order';
import { Product } from 'src/app/shared/classes/product';
import { GlobalService } from 'src/app/shared/services/global.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { CommonUtil } from 'src/app/shared/util/common-util';
import { CartModalComponent } from '../../modal/cart-modal/cart-modal.component';
import { QuickViewComponent } from '../../modal/quick-view/quick-view.component';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product: Product;
  @Input() sliderDetails: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() cartModal: boolean = false; // Default False

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public cnt: number = 0;
  public cntTotal: number = 0;
  public max_quantity: number = 0;
  public storeId: number = 0;

  isFavourite: boolean = false;
  cartQuantity: any;
  cart_item_quantity: any;
  constructor(private productService: ProductService, private router: Router,private dialog: MatDialog,
    private toastrService: ToastrService, private globalService : GlobalService,
    private orderService: OrderService,private translateService: TranslateService ) { }

  ngOnInit(): void {
    this.storeId = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id');
    if(this.product.unit_of_measure == 'GR' || this.product.unit_of_measure == 'KG') {
      this.cnt = this.product.minimum_quantity * 1000;
      this.cntTotal =  this.product.minimum_quantity * 1000;
      this.max_quantity = Number(this.product.actual_maximum_quantity) / this.product.minimum_quantity;
      this.product.offer_price =  Number((this.product.offer_price * 1000).toFixed(2));
      this.product.was_price = Number((this.product.was_price * 1000).toFixed(2));
    } else {
      this.max_quantity = this.product.maximum_quantity;
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }
  // Change Variants


  // addToCart(product: any) {
  //   //this.productService.addToCart(product);
  // }

  addToWishlist(product: any) {
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)) {
      this.toastrService.error('Login to continue', 'Error');
      this.router.navigateByUrl('/public/login');
      return
    }
    product.is_on_wishlist = (product.is_on_wishlist) ? false : true;
    let prod = JSON.parse(JSON.stringify(product));
    if(prod.is_on_wishlist) {
      prod.minimum_quantity = prod.unit_of_measure == 'KG' || prod.unit_of_measure == 'GR' ? prod.minimum_quantity * 1000 : prod.minimum_quantity;
    this.openWhislistPopup(prod);
      // this.productService.addToWishlist(product)
    } else {
      this.productService.removeWishlistItem(product);
    }
    this.isFavourite = !this.isFavourite;   
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)) {
      this.toastrService.error('Login to continue', 'Error');
      this.router.navigateByUrl('/public/login');
      return
    } // ADD WISHLIST & ADDCART same functionality here
    this.productService.addToCartItem(product);
    //this.isFavourite = !this.isFavourite;
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }
  increment(product: any, type: string) {
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)) {
      this.toastrService.error('Login to continue', 'Error');
      this.router.navigateByUrl('/public/login');
      return
    }
    let isKg = product?.unit_of_measure == 'KG' || product?.unit_of_measure == 'GR';
    let maxQuantity = isKg ? Number(product.actual_maximum_quantity) * 1000 : product?.maximum_quantity;
    let minQty = isKg ? product.minimum_quantity * 1000 : product.minimum_quantity;
    console.log('incement', minQty, maxQuantity, product?.cart_item_quantity );
    if (type === 'increment' && product?.cart_item_quantity < maxQuantity) {
      isKg ? product.cart_item_quantity += minQty : product.cart_item_quantity++ ;
      this.updateQuantity(product, product?.cart_item_quantity);
    } else if (type === 'decrement' && product?.cart_item_quantity > 1 && product?.cart_item_quantity > minQty) {
      isKg ? product.cart_item_quantity -= minQty : product.cart_item_quantity--;
      this.updateQuantity(product, product?.cart_item_quantity);
    } else if (type === 'decrement' && (product?.cart_item_quantity === 1 || product?.cart_item_quantity == minQty) ) {
      isKg ? product.cart_item_quantity -= minQty : product.cart_item_quantity--;
      this.deleteCart(product);

    } 
  }

  updateQuantity(product: any, qty: any) {
    if (!(JSON.parse(localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER))?.customer_id)) {
      this.toastrService.error('Login to continue', 'Error');
      this.router.navigateByUrl('/public/login');
      return
    }
    if(!this.globalService.customerDeliveryAddress) {
      this.toastrService.error('Add delivery address', 'Error');
      this.router.navigateByUrl('/pages/myaddress');
      return;
    }
    let deliveryType = localStorage.getItem('CustomerDeliveryType');
    let is_instant = product?.is_instant
    if( deliveryType == 'Instant' && !is_instant){
        this.dialog.open(InstantErrorPopupComponent,{
          width: '760px',
          height: '384px',
          maxWidth:'95vw',
          
        })
      // this.toastrService.error('Instant delivery not availble for this product', 'Error');
      // this.router.navigateByUrl('/public/login');
      return
    }
    this.productService.updateCartQuantity(product, qty, 'productCard');
    let addToCart: AddToCart = {};
    addToCart = CommonUtil.prepareAddCart(product, qty);
    this.orderService.addToCartItem(addToCart).subscribe((removeListResponse: any) => {
      if (removeListResponse.is_success) {
        product.is_on_cart = true;
        product.cart_item_quantity = (product.cart_item_quantity == 0) ? 1 * qty : product.cart_item_quantity;
        this.globalService.setCartCountState('getCartCount');
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasteraddedcart'), this.translateService.instant('myprofile.mywishlist.label.success!'));
        //this.getCartList();
      } else if (removeListResponse?.message === CUSTOMER_PORTAL_CONSTANT.ADDCART_ERROR) {
        this.toastrService.error(this.translateService.instant(CUSTOMER_PORTAL_CONSTANT.ADDCART_ERROR_MESSAGE), this.translateService.instant('myprofile.mywishlist.label.error!'));
      }
    });
  }

  getValue(offerPrice: any, type: string): any {
    if(offerPrice && offerPrice > 0) {
      let parts = offerPrice.toString().split('.');
        if(type === 'Int') {
          return parts[0] | 0;
        } else {
           return (parts[1]) ? parts[1].length > 1 ? parts[1] : parts[1]+'0' : '00';      
        }
    }
  }

  convertQty(rowData: any, type: string = 'unit') {
    let cnvrtQty: any = 0;
    if(rowData.unit_of_measure == 'KG' || rowData.unit_of_measure == 'GR') {
      cnvrtQty = rowData.cart_item_quantity;
      if(type == 'unit')
        cnvrtQty = cnvrtQty >= 1000 ? cnvrtQty / 1000 + 'Kg' : cnvrtQty + 'g';
      else
        cnvrtQty = cnvrtQty >= 1000 ? cnvrtQty / 1000 : cnvrtQty;
    } else {  
      cnvrtQty = rowData.cart_item_quantity;
    }
    return cnvrtQty;
  }

    // Decrement
    deleteCart(product) {
      console.log('product ============', product);
      let addToCart: AddToCart = {};
      addToCart = CommonUtil.prepareAddCart(product, product.cart_item_quantity);
      addToCart.shopping_cart_type = CUSTOMER_PORTAL_CONSTANT.SHOPPING_CART;
      this.orderService.removeCartItem(addToCart).subscribe((removeListResponse: any) => {
        //console.log('removeListResponse =', removeListResponse);
        if (removeListResponse.is_success) {
          this.globalService.setCartCountState('getCartCount');
          //this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasterremovewishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));
          this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasterremovedcart'), this.translateService.instant('myprofile.mywishlist.label.success!'));
        }
  
      });
    }
    openWhislistPopup(productData: any) {
      const dialogRef = this.dialog.open(WishlistPopupComponent, {
        width: '571px',
        maxWidth:'90vw',
        height: '70vh',
        data: {
        ...productData
        }
      });
      dialogRef.afterClosed().subscribe(response => {
        console.log(response, productData);
        // if(!response) {
        //   let heartClass: any = document.getElementsByClassName('heart');
        //   console.log('heart class',heartClass)
        // }
        // (response && response?.result === 'Cancel') ? '' : this.createNewCollection(response?.result);
      });
    }
}
