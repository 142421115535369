import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ServiceConfigs } from "src/app/constant/service.config";
import { environment } from "src/environments/environment";
import { UserLoginRequest, UserLoginResponse } from "../classes/user";
import { CommonUtil } from "../util/common-util";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public Sliders: any;
  public url: string;
  public refreshTokenTimeout: any;

  constructor(private http: HttpClient,
    private config: ServiceConfigs,
    private authService: AuthService) { }


  // Login
  public verifyUser(apiRequest: UserLoginRequest): Observable<UserLoginResponse> {
    this.url = (environment.mock) ? this.config.GET_LOGIN_USER.mockUrl : this.config.GET_LOGIN_USER.url;
    return this.http.post<UserLoginResponse>(`${this.config.GET_LOGIN_USER.url}`, apiRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userLoginErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userLoginErrorResponseData);
      }),
    );
  }

  public startRefreshTokenTimer(user: any) {
    const token = user.access_token;
      const jwtBase64 = token.split('.')[1];
      const jwtToken = JSON.parse(atob(jwtBase64));
      const expires = new Date(jwtToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - (60 * 1000);
      console.log('refresh token implement', timeout, jwtToken);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  refreshToken() {
    console.log('refresh token called');
    this.getRefreshToken().subscribe((res: any) => {
      if (res.data && res.data.refresh_token) {
        localStorage.setItem('currentUser', JSON.stringify(res.data));
        this.authService.setAccessToken(res.data);
        this.startRefreshTokenTimer(res.data);
      }
    });
  }

  getRefreshToken() {
    let apiRequest = {customer_id: CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'), 
        refresh_token: CommonUtil.getStoreIdFromLocalStorageUserProfile('refresh_token')}
    this.url = (environment.mock) ? this.config.REFRESH_TOKEN.mockUrl : this.config.REFRESH_TOKEN.url;
    return this.http.post<UserLoginResponse>(`${this.url}`, apiRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userLoginErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userLoginErrorResponseData);
      }),
    );
  }

    // Login
    public sendRecoveryLink(apiRequest: any): Observable<UserLoginResponse> {
      this.url = this.config.SEND_RECOVERYLINK.url;
      return this.http.post<UserLoginResponse>(`${this.url}`, apiRequest).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => {
          const userLoginErrorResponseData: UserLoginResponse = {
            data: httpErrorResponse.error,
            is_success: false,
            message: httpErrorResponse.error.message,
            status: httpErrorResponse.error.status
          };
  
          return of(userLoginErrorResponseData);
        }),
      );
    }
    public resetPassword(apiRequest: any): Observable<UserLoginResponse> {
      this.url = this.config.RESET_PASSWORD.url;
      return this.http.post<UserLoginResponse>(`${this.url}`, apiRequest)
    }
  // Login
  public logout(): Observable<any> {

    return this.http.get<UserLoginResponse>(`${this.config.LOGOUT_USER.url}`).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userLoginErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userLoginErrorResponseData);
      }),
    );
  }

  // SignUp
  public signUpUser(apiRequest: any): Observable<UserLoginResponse> {
    //this.url=(environment.mock)? this.config.POST_SIGNUP_USER.mockUrl: this.config.POST_SIGNUP_USER.url;
    return this.http.post<UserLoginResponse>(`${this.config.POST_SIGNUP_USER.url}`, apiRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const userSignUpErrorResponseData: UserLoginResponse = {
          data: httpErrorResponse.error,
          is_success: false,
          message: httpErrorResponse.error.message,
          status: httpErrorResponse.error.status
        };

        return of(userSignUpErrorResponseData);
      }),
    );
  }
}
