import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare const gapi: any;
@Injectable({
  providedIn: 'root'
})
export class GoogleSigninService {
  public user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor() {}
  /**
* Calling Google login API and fetching account details.
* @param callback Callback to function
*/
  public authenticateUser(clientId,callback) {
    let auth2 : any;
    gapi.load('auth2', () => {
      auth2 = gapi
        .auth2
        .init({client_id: clientId, scope: 'email', plugin_name:'login'});
      //Login button reference
      console.log(this)
      let that = this;
      let element : any = document.getElementById('google-login-button');
      auth2.attachClickHandler(element, {},  (googleUser) => {
        //Getting profile object
        console.log(callback,that)
        let profile = googleUser.getBasicProfile();
        // callback();
        //Setting data to localstorage.
        localStorage.setItem('token', googleUser.getAuthResponse().id_token);
        // Alternatively you can create an object and return it like that - 
      let  userDetails = {
        token: googleUser.getAuthResponse().id_token, name: profile.getName(),firstName : profile.getGivenName(),lastName: profile.getFamilyName(), image:
        profile.getImageUrl(), email: profile.getEmail() };
        localStorage.setItem('socialLogin', JSON.stringify(userDetails))
        that.user$.next(userDetails)
      // return userDetails
      console.log(this)
      }, function (error) {
        alert(JSON.stringify(error, undefined, 2));
      })
    })
  }
  /**
* Logout user from Google
* @param callback Callback to function
*/
  userLogout() {
    //You will be redirected to this URL after logging out from Google.
    let homeUrl = window.location.hostname
    let logoutUrl = "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah" +
        "/logout?continue=" + homeUrl;
    document.location.href = logoutUrl;
    // callback();
  }
}
