import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IDebitParams } from '../shared/classes/order';
import { OrderService } from '../shared/services/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-debit-payment',
  templateUrl: './debit-payment.component.html',
  styleUrls: ['./debit-payment.component.scss']
})
export class DebitPaymentComponent implements OnInit {

  @ViewChild('paymentFormRef') paymentFormRef;
  public actionType: string = environment.qpay_url;

  paymentForm: any = { };
  constructor(private http: HttpClient, private route: ActivatedRoute,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      if (params) {
        let debitParam: IDebitParams = { PUN: params.PUN, Amount: params.Amount, PaymentDescription: params.PaymentDescription, NationalID: params.NationalID, CustomerId: params.CustomerId, StoreId: params.StoreId };
        this.orderService.generateHashCode(debitParam).subscribe((responseData: any) => {
          console.log('hash code', responseData);
          if (responseData.is_success) {
            this.getDebitCardParams(responseData.data, debitParam);
            setTimeout(() => {
              console.log('request data ', this.paymentForm);
              this.paymentFormRef.nativeElement.submit();
            }, 500);
          }
        })
      }
    })
  }

  onSubmit() {
    console.log('form value', this.paymentForm, this.paymentFormRef);
    this.paymentFormRef.nativeElement.submit();
  }

  getDebitCardParams(response: any, params: IDebitParams) {

    this.paymentForm = {
      Amount: response.Amount,
      CurrencyCode: response.CurrencyCode,
      PUN: response.PUN ? response.PUN : params.PUN,
      MerchantModuleSessionID: response.MerchantModuleSessionID ? response.MerchantModuleSessionID : params.PUN,
      PaymentDescription: response.Description,
      NationalID: response.NationalId ? response.NationalId : params.NationalID,
      MerchantID: response.MerchantId,
      BankID: response.BankId,
      Lang: 'en',
      Action: 0,
      SecureHash: response.SecureHash ? this.formatSecureHash(response.SecureHash) : '86bd42c69b03c318a7b9dd26afbe340e1833f94073c05f67b5f0ef5bb852f956',
      TransactionRequestDate: response.TransactionRequestDate,
      ExtraFields_f14: response.ExtraField ? response.ExtraField : 'https://megamart.qa/shop/payment-status',
      Quantity: response.Quantity
    }
    console.log('debit card', response);
  }

  formatSecureHash(secureHash: string) {
    let hash = '';
    if(secureHash.split('SecureHash').length > 1) {
      hash = secureHash.split('SecureHash')[1].substr(1);
    } else {
      hash = secureHash;
    }
    return hash;
  }
}
