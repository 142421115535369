import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigate-store',
  templateUrl: './navigate-store.component.html',
  styleUrls: ['./navigate-store.component.scss']
})
export class NavigateStoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.detectDevice();
  }

  public detectDevice() {
    const userAgent = navigator.userAgent || navigator.vendor || window["opera"];

    // Check if the user is on an iPhone, Android phone, iPad, or any mobile device
    if (/android/i.test(userAgent)) {
      window.location.replace("https://play.google.com/store/apps/details?id=com.megamart.customer");
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window["MSStream"]) {
      window.location.replace("https://apps.apple.com/in/app/mega-mart-online-grocery/id6673892886");
      return "iOS";
    } else {
      console.log('window app');
    }
  }
}
