import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { GlobalService } from './shared/services/global.service';
import { AppSettings } from './shared/classes/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomeService } from './shared/services/home.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public urlList: any = ['/privacypolicy'];
  public isKeyFrame: boolean = false;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private spinner: NgxSpinnerService,
    private loader: LoadingBarService, translate: TranslateService, private homeService: HomeService,
    private router: Router, public globalService: GlobalService) {
      this.router.events.subscribe((urlParams: any) => {
        if(this.urlList.includes(urlParams.url)) {
          this.isKeyFrame = true;
          document.querySelectorAll('.external-script').forEach(el => el.remove());
        }
        return;
      });
    const isGuestUser = !(JSON.parse(localStorage.getItem('currentUser'))?.customer_id);
    if (isGuestUser) {
      this.homeService.getPrimaryStore().subscribe(result => {
        if (result.is_success) {
          localStorage.setItem('currentUser', JSON.stringify(result.data));
          homeService.setCustomerStoreId(result.data.store_id);
        }
      });
    }
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 3000);
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.globalService.previousAppRouterUrl = this.router.url;
        }
      });


    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
    let appSettings: AppSettings;
    if (localStorage.getItem('appSettings') !== null) {
      appSettings = JSON.parse(localStorage.getItem('appSettings'));
      const lang = (appSettings && appSettings.layout !== null) ? appSettings.layout : 'rtl';
      this.appLayout(lang);
    } else {
      const lang = 'rtl';
      this.appLayout(lang);
    }
  }

  appLayout(val: string) {
    if (val === 'ltr') {
      document.body.classList.remove('ltr');
      document.body.classList.add('rtl');
      this.globalService.translate('ar');
    } else {
      document.body.classList.remove('rtl');
      document.body.classList.add('ltr');
      this.globalService.translate('en');
    }
  }
}
