import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-container',
  templateUrl: './menu-container.component.html',
  styleUrls: ['./menu-container.component.scss']
})
export class MenuContainerComponent implements OnInit {
  categories = [];
  submenus: object;
  nestedSubmenus:object;
  items: object;
  iSArabic : boolean = false;
  selectedMenu: object | string| null = null;
  selectedSubmenu: object | null = null;
  selectedNestedSubmenu: object | null = null;
  selectedItem: object | null = null;
constructor(private router: Router,public dialogRef: MatDialogRef<MenuContainerComponent>){
  // this.homeService.getcategories(undefined,undefined,31).subscribe((result) => {
  //   let response: any = result?.data;
  //   let responseData: any = response?.records;
  //   this.categories = responseData;
  // })
}
ngOnInit(): void {
  this.categories = JSON.parse(localStorage.getItem('megaMenu')).megaMenu;
  console.log(JSON.parse(localStorage.getItem('appSettings')).language)
  this.iSArabic = JSON.parse(localStorage.getItem('appSettings')).language == 'ar' ? true : false;
}
  selectMenu(menu: object): void {
    this.selectedMenu = menu;
    this.selectedSubmenu = undefined;
    this.selectedNestedSubmenu = undefined;
    this.selectedItem = undefined;
  }
selectAllMenu(){
  this.selectedMenu = 'All';
  this.selectedSubmenu = undefined;
}
  selectSubmenu(submenu: object): void {
    this.selectedSubmenu = submenu;
    this.selectedNestedSubmenu = undefined;
    this.selectedItem = undefined;
  }

  selectNestedSubmenu(nestedSubmenu: object): void {
    this.selectedNestedSubmenu = nestedSubmenu;
    this.selectedItem = undefined;
  }

  selectItem(item: object): void {
    this.selectedItem = item;
  }
  navigateCategoryList(category: any) {
    console.log(category);   
    let selectedDetails = {
      selectedMenu :this.selectedMenu,
      selectedSubmenu : this.selectedSubmenu,
      selectedNestedSubmenu :this.selectedNestedSubmenu,
      selectedItem : this.selectedItem
    }
    localStorage.setItem('selectedCategory',JSON.stringify(selectedDetails));
    this.dialogRef.close() 
    this.router.navigate(["/shop/category-product-list", category?.category_id]);
  }
  getAllProducts(){
    let selectedDetails = {
      selectedMenu :this.selectedMenu
    }
    localStorage.setItem('selectedCategory',JSON.stringify(selectedDetails));
    this.dialogRef.close() 
    this.router.navigate(["/shop/all-products"]);

  }
}
