import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface IServiceConfig {
    url: string;
    mockUrl?: string;
    mockUrl1?: string;
    mockUrl2?: string;
    mockUrl3?: string;
    mockUrl4?: string;
}

@Injectable({ providedIn: 'root' })
export class ServiceConfigs {

    //private apiBaseUrl: string = environment.apiBaseUrl;
    private apiEndpoint: string = environment.apiEndpoint;

    public GET_LOGIN_USER:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/login/token`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/api/login/token`,
    };
    public REFRESH_TOKEN: IServiceConfig = {
        url: `${this.apiEndpoint}/api/login/newtoken`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/api/login/newtoken`,
    }
    public SEND_RECOVERYLINK:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/password/forget`

    };
    public RESET_PASSWORD:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/password/recovery`

    };
    public POST_SIGNUP_USER:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/registration`,        
        mockUrl: `${environment.apiBaseUrl}/api/websf/customer/registration`,
    };
    public GET_SLIDERS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/banners/getall`, // Home Screen Banner Slider
        mockUrl: `${environment.apiBaseUrl}/assets/data/sliders.json`,
    };
    // Brand list top and bottom slider (Banner) , 0th is top , 1th is bottom
    public GET_BRANDSLIDERS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/brands/banner`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/brandSliders.json`,
    };
    public GET_ADSCONTAINER: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/banners/offers`, // Home screen adsContainer
        mockUrl: `${environment.apiBaseUrl}/assets/data/adscontainer.json`,
    };
    public GET_CATEGORIES: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/category/getall`,  // Need to pass ?storeId=1
        mockUrl: `${environment.apiBaseUrl}/assets/data/categories.json`,
    };
    public GET_CATEGORIESLIST: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/categoryList.json`,
    };
    public GET_INDIVIDUAL_CATEGORIES_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/product/productlist`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/categoryList-`,
    };    
    public GET_TOP_CATEGORIESLIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/category?ListCount=3`, // ?listCount=4 , If required for business we can dynamic
        mockUrl: `${environment.apiBaseUrl}/assets/data/topcategoryList.json`,
    };
    //Get Top categories Banners
    public GET_TOP_CATEGORIES_BANNERS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/banners/filters`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/topCategoriesBanners.json`,
    };
    //Get Brand Banner 
    public GET_BRAND_BANNERS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/banners/filters`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/brandbanner.json`,
    };
    // Get TopGategoriesDetails
    public GET_TOP_CATEGORIES_DETAILS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/category/getall`, 
        mockUrl: `${environment.apiBaseUrl}/assets/data/topCategoriesDetails.json`,
    };
    public GET_ALL_PRODUCTS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/getall`, 
        mockUrl: `${environment.apiBaseUrl}/assets/data/topCategoriesDetails.json`,
    };
    public GET_WEEKOFFER: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/discount/product`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/weekoffer.json`,
    };
    public GET_MOREDEALS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/brands/Advertisement`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/deals.json`,
    };
    public GET_PRIMARY_STORE: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/store/getprimary`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/getprimary.json`,
    };
    // Brand Advertisement
    public GET_BRAND_ADS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/brands/Advertisement`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/brandlist-advertisement.json`,
    };
    public GET_BRANDS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/manufacturer/getall`, // Need to pass storeId=1
        mockUrl: `${environment.apiBaseUrl}/assets/data/brands.json`,
    };
    public GET_PURCHASEDITEMS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/recent/purchase/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/purchaseditems.json`,
    };
    public GET_ESSENTIALSITEMS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/category/dailyessential/get`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/purchaseditems.json`,
    };
    public GET_RELATEDITEMS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/related/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/purchaseditems.json`,
    };
    public GET_REVIEWS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/review/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/purchaseditems.json`,
    };
    public GET_MANUFACTURERITEMS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/manufacturer/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/purchaseditems.json`,
    };
    public GET_PRODUCTLIST: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/productlist.json`,
    };
    //
    public GET_BRAND_PRODUCT_LIST: IServiceConfig = {
        // BrandsSingleProduct need to change a single service
        // api/webstorefront/brands/BrandsAllProduct?storeId=1&customerId=1   //  brandId=0 , will get / consider all
        url: `${this.apiEndpoint}/api/websf/product/manufacturer/getall`, 
        mockUrl: `${environment.apiBaseUrl}/assets/data/brandlist-products.json`,
    };
    public GET_MY_ORDERS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myorders.json`,
    };
    public GET_RETURN_REQUEST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/returnrequest.json`,
    };
    public GET_RETURN_REQUEST_STATUS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/returnrequeststatus.json`,
    };
    public GET_RETURN_REQUEST_REASONS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/get/returnoptions`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/returnrequeststatus.json`,
    };
    public GET_PLACED_ORDERS_DETAILS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/getitems`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/placedorders.json`,
    };
    public GET_MY_ADDRESS_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/address/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myaddresslist.json`,
    };
    public GET_MY_WISH_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/wishlist.json`,
    };
    public GET_MY_WISH_LIST_GROUP: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/wishlist/getgroup`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/wishlist.json`,
    };
    public GET_POLICIES_LIST_EN: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/policy`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/policies.json`,
    };
    public GET_POLICIES_LIST_AR: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/policies-ar.json`,
    };
    public GET_COUPONS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/discount/get/id`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/coupons.json`,
    };
    public GET_CREDIT_COUPONS_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/discount/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/creditandcoupons.json`,
    };
    public GET_CHECKOUT_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/orderdetails`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/checkout.json`,
    };
    public GET_DELIVERY_SLOT: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/deliveryslots/get`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/deliveryslot.json`,
    };
    public GET_COUPON_VERIFY: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/couponcode`
    };
    public GET_PERSONAL_DETAILS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/details/get`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/personaldetails.json`,
    };
    public UPDATE_PERSONAL_DETAILS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/details/update`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/personaldetails.json`,
    };
    public CHANGEPASSWORD: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/changepassword`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/changepassword.json`,
    };
    public CONTACTUS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/email/contactus`,
    };
    public GET_NOTIFICATIONS_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/notification/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/notifications.json`,
    };
    public GET_STORE_FINDER_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/storefinder.json`,
    };
    public GET_SUBSTITUTE_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/substituion/orderid/get`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/substitute-data.json`,
    };
    public UPDATE_SUBSTITUTE_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/substitutions/status/publish`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/substitute-data.json`,
    };
    public GET_CART_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/getall`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/cart.json`,
    };
    public GET_WISH_LIST: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/wishlist/getall`
    };
    public CREATE_WISH_LIST_GROUP: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/wishlistgroup`
    };
    public GET_CATEGORIESVIEW: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/categoriesview.json`,
    };
    public GET_CATEGORIESLIDER: IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/category/banner`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/categoryslider.json`,
    };
    public GET_BRANDPRODUCTLIST: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/brandproductlist.json`,
    };  
    public ABOUTUSBANNER: IServiceConfig = {
        url: `${this.apiEndpoint}/apicalls`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/aboutus.json`,
    };    
    public GET_SEARCH_BY_PRODUCT:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/search/getall`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/search-product.json`,
    };
    public GET_SEARCH_BY_PRODUCT_AND_CATEGORY:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/search/getall`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/search-product.json`,
    };
    // Add cart & add Wishlist
    public GET_SEARCH_BY_PRODUCT_ID:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/details/get`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/getProductById.json`,
    };
    public ADD_CART:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/addcart`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/addWishList.json`,
    };
    //complete checkout
    public CHECKOUT_STATUS:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/checkout`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/checkoutstatus.json`,
    };
        //complete checkout
        public GET_SESSION:  IServiceConfig = {
            url: `${this.apiEndpoint}/api/websf/checkout/session/get`, // api/webstorefront/addcartwishlist
            //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
            mockUrl: `${environment.apiBaseUrl}/assets/data/checkoutstatus.json`,
        };
    public RETURN_REQUEST:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/returnrequest`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/checkoutstatus.json`,
    };
    // RemoveCart
    public REMOVE_CART:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/removecart`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/addWishList.json`,
    };
    // ADD WishList API
    public ADD_WISH_LIST:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/addwishlist`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/addWishList.json`,
    };
    public ADD_WISH_LIST_TO_COLLECTION:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/wishlist/add`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/addWishList.json`,
    };
    public ADD_WISH_LIST_COLLECTION_TO_CART:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/addwishlistitemstocart`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/addWishList.json`,
    };
    public UPDATE_REVIEW:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/review`, // api/webstorefront/addcartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/addWishList.json`,
    };
    // Common use - Discard card & Remove wishlist
    public DISCARD_ORDER:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/cart/removecart`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myorders.json`,
    };
    // Remove wishlist
    public REMOVE_WISH_LIST:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/wishlist/remove`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myorders.json`,
    };

    // Common use - Discard card & Remove wishlist
    public REMOVE_CARD_LIST:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/webstorefront/cart/removecartlist`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myorders.json`,
    };

    // Common use - Discard card & Remove wishlist
    public GET_SORTING_TYPES:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/product/sortingtype/getall`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/sortingTypes.json`,
    };
    // CART COUNT
    public GET_CART_COUNT:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/count`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/cart-count.json`,
    };
    //GetCustomerDeliveryType
    public GET_CUSTOMER_DELIVERY_TYPE: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/deliverytype/get`, // Home Screen Banner Slider
        mockUrl: `${environment.apiBaseUrl}/assets/data/customer-delivery-type.json`,
    };

    //GetCustomerDeliveryType
    public UPDATE_CUSTOMER_DELIVERY_TYPE: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/deliverytype`, // Home Screen Banner Slider
        mockUrl: `${environment.apiBaseUrl}/assets/data/customer-delivery-type.json`,
    };

    // SetDefault Address
    public SET_DEFAULT_ADDRESS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/address/setdefault`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myaddresslist.json`,
    };

    // Download Order Invoice
    public DOWNLOAD_ORDER_INVOICE: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/pdfinvoice`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myaddresslist.json`,
    };


    // Download Order Invoice
    public CANCEL_ORDER: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/order/cancel`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myaddresslist.json`,
    };

    
    // My address - Area List
    public GET_AREA_LIST:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/get/area`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/arealist.json`,
    };

    // My address - Add new Address
    public ADD_NEW_ADDRESS:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/address/add`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/arealist.json`,
    };
    
    // My address - City List
    public GET_CITY_LIST:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/city/getall`, // /api/webstorefront/removecartwishlist
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/citylist.json`,
    };

    //LOGOUT API
    public LOGOUT_USER:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/login/signout`,
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/api/login/signout`,
    };
    public PAYMENT_SIGNIN:  IServiceConfig = {
        url: environment.payment_merchant_url, 
        //mockUrl: `${environment.apiBaseUrl}/assets/data/login.json`,
        mockUrl: `${environment.apiBaseUrl}/assets/data/myorders.json`,
    };

    public UPDATE_PAYMENT_STATUS:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/online/paid`
    };

    public REMOVE_ADDRESS: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/address/remove`
    }

    public UPDATE_EXISTING_ADDRESS:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/customer/address/update`
    };

    public UPDATE_NOTIFICATIONS_STATUS:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/notification/Update/status`
    };

    public UPDATE_QPay_STATUS:  IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/checkout/qpay/get`
    };

    public VALIDATE_COUPON_CODE: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/cart/couponcode/validation`
    }

    public GENERATE_HASH: IServiceConfig = {
        url: `${this.apiEndpoint}/api/websf/checkout/qpay/get`
    }
    
}