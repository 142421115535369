import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { Sliders } from '../classes/home';
import { AddToCart, CheckoutStatus, DiscardOrderRequest, DiscardOrderResponse, MyOrders, Order, RemoveWishList, ReturnRequest } from '../classes/order';
import { AddWishListResponse } from '../classes/wishlist';
import { CommonUtil } from '../util/common-util';

const state = {
  checkoutItems: JSON.parse(localStorage['checkoutItems'] || '[]')
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public url: string;
  public paymentSessionId :  any;
  constructor(private router: Router, private http: HttpClient,
    private config: ServiceConfigs,
    private toastrService: ToastrService,
    private translateService: TranslateService) { }

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable(observer => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  // Create order
  public createOrder(product: any, details: any, orderId: any, amount: any) {
    var item = {
        shippingDetails: details,
        product: product,
        orderId: orderId,
        totalAmount: amount
    };
    state.checkoutItems = item;
    localStorage.setItem("checkoutItems", JSON.stringify(item));
    localStorage.removeItem("cartItems");
    this.router.navigate(['/shop/checkout/success', orderId]);
  }

// My Orders 
public getMyOrders(): Observable<MyOrders[]> {
  this.url=(environment.mock)? this.config.GET_MY_ORDERS.mockUrl: this.config.GET_MY_ORDERS.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }
   queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);


  return this.http.get<MyOrders>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

// Orders Placed API
public getOrderPlacedDetails(orderId: number): Observable<MyOrders[]> {
  this.url=(environment.mock)? this.config.GET_PLACED_ORDERS_DETAILS.mockUrl: this.config.GET_PLACED_ORDERS_DETAILS.url;
  let queryParams = new HttpParams();
   queryParams = queryParams.append("order_id", orderId);
  return this.http.get<MyOrders>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

// Discard Orders || Remove wish list
public removeWishList(removeWishListRequest: RemoveWishList): Observable<any> {
  this.url=(environment.mock)? this.config.REMOVE_WISH_LIST.mockUrl: this.config.REMOVE_WISH_LIST.url;
    // REAL API HIT
    return this.http.post<DiscardOrderResponse>(`${this.url}`,
    removeWishListRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}

// Discard Orders
public removeCart(removeWishListRequest: RemoveWishList): Observable<any> {
  this.url=(environment.mock)? this.config.REMOVE_WISH_LIST.mockUrl: this.config.REMOVE_WISH_LIST.url;
    // REAL API HIT
    return this.http.post<DiscardOrderResponse>(`${this.url}`,
    removeWishListRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}

// Discard Orders || Remove wish list
public removeGroupCartWishList(discardOrderRequest: DiscardOrderRequest,type?: string): Observable<any> {

  if(environment.mock && type !== 'realAPI') { 
    return this.http.get<any>(`${this.config.REMOVE_CARD_LIST.mockUrl}`).pipe(
        catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
    );
  } else {      
    // REAL API HIT
    return this.http.post<any>(`${this.config.REMOVE_CARD_LIST.url}`,
    discardOrderRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );
    }
}

// AddToCart
public addToCartItem(removeWishListRequest: AddToCart): Observable<any> {
  this.url=(environment.mock)? this.config.ADD_CART.mockUrl: this.config.ADD_CART.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
    removeWishListRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}


//complete checkout

public checkoutStatus(checkoutrequest: CheckoutStatus): Observable<any> {
  this.url=(environment.mock)? this.config.CHECKOUT_STATUS.mockUrl: this.config.CHECKOUT_STATUS.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
    checkoutrequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}
public getSessionId(checkoutrequest: any): Observable<any> {
  this.url=this.config.GET_SESSION.url;
  let queryParams = new HttpParams();
    // REAL API HIT

    queryParams = queryParams.append("ApiOperation", checkoutrequest.apiOperation);
    queryParams = queryParams.append("Order.Currency", checkoutrequest.order.currency);
    queryParams = queryParams.append("Order.Amount", checkoutrequest.order.amount);
    queryParams = queryParams.append("Order.Id", checkoutrequest.order.id);
    queryParams = queryParams.append("Order.Description", checkoutrequest.order.description);
    queryParams = queryParams.append("Interaction.Operation", checkoutrequest.interaction.operation);
    queryParams = queryParams.append("Interaction.ReturnUrl", checkoutrequest.interaction.returnUrl);
    queryParams = queryParams.append("Interaction.Merchant.Name", checkoutrequest.interaction.merchant.name);
    queryParams = queryParams.append("Interaction.Merchant.Logo", checkoutrequest.interaction.merchant.logo);
    queryParams = queryParams.append("Interaction.DisplayControl.BillingAddress", checkoutrequest.interaction.displayControl.billingAddress);
    queryParams = queryParams.append("Interaction.DisplayControl.CustomerEmail", checkoutrequest.interaction.displayControl.customerEmail);
    return this.http.get<any>(`${this.url}`,
    {params : queryParams}).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}

// AddToCart
public removeCartItem(removeWishListRequest: AddToCart): Observable<any> {
  this.url=(environment.mock)? this.config.REMOVE_CART.mockUrl: this.config.REMOVE_CART.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
    removeWishListRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}

// Orders Placed API
public downloadOrderInvoice(order: any) {
  this.url=(environment.mock)? this.config.DOWNLOAD_ORDER_INVOICE.mockUrl: this.config.DOWNLOAD_ORDER_INVOICE.url;  
  window.open(this.url+'/?orderId='+order?.order_id);

}

// Cancel Orders
public cancelOrder(order: any): Observable<any> {
  this.url=(environment.mock)? this.config.CANCEL_ORDER.mockUrl: this.config.CANCEL_ORDER.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
    order).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}


public getReturnRequest(): Observable<ReturnRequest[]> {
  this.url=(!environment.mock)? this.config.GET_RETURN_REQUEST.mockUrl: this.config.GET_RETURN_REQUEST.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }
   queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

  return this.http.get<ReturnRequest>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

public getReturnRequestStatus(): Observable<ReturnRequest[]> {
  this.url=(!environment.mock)? this.config.GET_RETURN_REQUEST_STATUS.mockUrl: this.config.GET_RETURN_REQUEST_STATUS.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }
   queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

  return this.http.get<ReturnRequest>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public getReturnRequestReasons(): Observable<any> {
  this.url = this.config.GET_RETURN_REQUEST_REASONS.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }
   queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);

  return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public returnRequest(checkoutrequest: any): Observable<any> {
  this.url=this.config.RETURN_REQUEST.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
     checkoutrequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );
}
public paymentSignin(requestPayload): Observable<any> {

  
  this.url=(environment.mock)? this.config.PAYMENT_SIGNIN.mockUrl: this.config.PAYMENT_SIGNIN.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
    requestPayload).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}
getPaymentSessionId(){
  return this.paymentSessionId
}
public updatePaymentStatus(requestPayload): Observable<any> {

  
  this.url= this.config.UPDATE_PAYMENT_STATUS.url;
    // REAL API HIT
    return this.http.post<any>(`${this.url}`,
    requestPayload).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if (httpErrorResponse.error.status !== 404)
            this.toastrService.error(httpErrorResponse.error.detail || this.translateService.instant('common.label.serviceerror'));

        return of([]);
    })
    );

}

public getCouponValidation(subTotal: any): Observable<any> {
  this.url = this.config.VALIDATE_COUPON_CODE.url;
  let queryParams = new HttpParams();
  if (CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
    queryParams = queryParams.append("customer_id", CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id'));
  }
  queryParams = queryParams.append("store_id", `${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`);
  queryParams = queryParams.append("subTotal", subTotal);
  return this.http.get<any>(this.url, { params: queryParams }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

public generateHashCode(params: any): Observable<any> {
  this.url = this.config.GENERATE_HASH.url;
  if(!params.CustomerId) {
    params.CustomerId = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');
    params.StoreId = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id');
  }
  return this.http.get<any>(this.url, { params: params }).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}

}
