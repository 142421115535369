import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { CartService } from 'src/app/shared/services/cart.service';
import { WishListService } from 'src/app/shared/services/wishlist.service';
import { PopUpComponent } from '../popup/popup.component';

@Component({
  selector: 'app-wishlist-popup',
  templateUrl: './wishlist-popup.component.html',
  styleUrls: ['./wishlist-popup.component.scss']
})
export class WishlistPopupComponent implements OnInit {
public wishListGroups = [];
  constructor(private cartService: CartService,private translateService : TranslateService, private toastrService :ToastrService,
     private dialog: MatDialog,public dialogRef: MatDialogRef<WishlistPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.cartService.getMyWishListGroup().subscribe((result)=>{
      console.log(result);
      if(result.is_success){
        this.wishListGroups = result.data.records
      }
    })
   }

  ngOnInit(): void {
  }
  confirmationPopup() {
    console.log(this.data)
    let selectedGroups = this.wishListGroups.filter((wishList) => wishList.checked);
    if(selectedGroups.length > 0){
    let myRequests = [];
    let requestpayload ={
      "group_id": [],
      "group_name": "",
      "product_id": this.data.product_id,
      "quantity": this.data.minimum_quantity,
    }
    selectedGroups.forEach((collection)=>{
     requestpayload.group_id.push(collection.group_id)

    })
    this.cartService.addWishlist(requestpayload).subscribe((result)=> {
      console.log(result);
      if(result[0]['is_success']){
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasteraddedwishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));

      }
    })} else {
      this.addProductWithNewCollection('');
    }
    this.dialogRef.close(selectedGroups)
  }
  close(){
    this.dialogRef.close();
  }
  openPopup() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(PopUpComponent, {
      width: '571px',
      height: '207px',
      data: {
        // title: 'Confirmation',
        message: 'Testformfield',
        wishList: this.wishListGroups
      }
    });
    dialogRef.afterClosed().subscribe(response => {
      (response && response?.result === 'Cancel') ? '' : this.addProductWithNewCollection(response?.result);
    });
  }

  addProductWithNewCollection(collectionName){
    let requestpayload ={
      "group_name": collectionName ,
      "product_id": this.data.product_id,
      "quantity": this.data.minimum_quantity,
      "group_id":[]
    }
    this.cartService.addWishlist(requestpayload).subscribe((result)=> {
      console.log(result);
      if(result['is_success']){
        this.toastrService.success(this.translateService.instant('myprofile.mywishlist.label.toasteraddedwishlist'), this.translateService.instant('myprofile.mywishlist.label.success!'));

      }
    })
  }
}
