import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CUSTOMER_PORTAL_CONSTANT } from 'src/app/constant/constant';
import { AppSettings } from '../../classes/user';
import { GlobalService } from '../../services/global.service';
import { HomeService } from '../../services/home.service';
import { PoliciesService } from '../../services/policies.service';
import { CommonUtil } from '../../util/common-util';

@Component({
  selector: 'app-footer-five',
  templateUrl: './footer-five.component.html',
  styleUrls: ['./footer-five.component.scss']
})
export class FooterFiveComponent implements OnInit {
  policyList: any;
  categories: any;
  public layout: string;
  public isKeyFrame: boolean = false;
  public urlList: any = ['/home/terms&conditions', '/privacypolicy', '/home/return&cancellationpolicy', '/shop/aboutus'];

  constructor(private homeService: HomeService ,
    private policiesService: PoliciesService, 
    public globalService: GlobalService,
    private router: Router,
    private toastrService: ToastrService) { 
    this.policiesService.getPoliciesList().subscribe((policyList: any) => {
      let ls: AppSettings = JSON.parse(localStorage.getItem('appSettings'));
      this.layout = (ls !== null && ls.layout === 'ltr') ? 'rtl' : 'ltr';
      this.policyList = policyList;
    });
  }

  ngOnInit(): void {
    
    this.router.events.subscribe((urlParams: any) => {
      if(this.urlList.includes(urlParams.url))
          this.isKeyFrame = true;
    });
    this.globalService.getMegamenuDetails().subscribe((menu) =>{ console.log('mega menu data', menu);this.categories = menu.megaMenu});;
    
    this.isKeyFrame = window.location !== window.parent.location;
  }
  public viewPolicy(policyType:any): void {
    this.router.navigate(['home/termsandpolicyview/',policyType.split(' ').join('').toLowerCase()]);

    }
    // //console.log('content ==', content);
    // this.globalService.termsAndConditionsHeading = content.policy_name; 
    // this.globalService.termsAndConditionsReadMore = content.content;
    // this.router.navigate(['home/terms-and-policy-view']);
    public validateUserWithNavigation(navigateUrl: string): void {
      if (navigateUrl == 'shop/store-finder') {
        this.router.navigateByUrl(navigateUrl);
      } else if (localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) !== null && localStorage.getItem(CUSTOMER_PORTAL_CONSTANT.CURRENT_USER) && CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')) {
        this.router.navigateByUrl(navigateUrl);
      } else {
        this.toastrService.error('Login to continue', 'Error');
        this.router.navigateByUrl('/public/login');
      }
    }

    navigateCategoryList(category: any) {
      console.log(category);    
      this.router.navigate(["/shop/category-product-list", category?.category_id]);
    }
    customizeLayoutType(val) {
      let appSettings: AppSettings = {};
      appSettings.layout = val;
      appSettings.language = (val === 'ltr') ? 'ar' : 'en';
      if (val == 'ltr') {
        document.body.classList.remove('ltr')
        document.body.classList.add('rtl')
        this.layout = 'rtl';
        this.globalService.translate('ar');
      } else {
        document.body.classList.remove('rtl');
        document.body.classList.add('ltr');
        this.layout = 'ltr';
        this.globalService.translate('en');
      }
      localStorage.setItem('appSettings', JSON.stringify(appSettings));
      window.location.reload();
    }
    explorecategory() {
      this.router.navigate(['/shop/category-list']);
    }
    onNavigateStore(url: any) {
      window.open(url);
    }
  }

