import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ServiceConfigs } from 'src/app/constant/service.config';
import { environment } from 'src/environments/environment';
import { Cart } from '../classes/cart';
import { CommonUtil } from '../util/common-util';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  public url: string;

  constructor(private http: HttpClient,
    private config: ServiceConfigs) { }

  
// My Address list 
public getCartList(shoppingCartType?: string): Observable<Cart[]> {
  this.url=(environment.mock) ? this.config.GET_CART_LIST.mockUrl: this.config.GET_CART_LIST.url;
  let queryString : string = '?customer_id='+`${CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')}`
  +'&store_id='+`${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}`
  +'&page_index=1'
  +'&page_size=100'
  +'&shopping_cart_type='+`${shoppingCartType}`;

  return this.http.get<Cart>(`${this.url}${queryString}`).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public getWishList(): Observable<Cart[]> {
  this.url= this.config.GET_WISH_LIST.url;
  let queryString : string = '?customer_id='+`${CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')}`
  +'&store_id='+`${CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id')}` + '&shopping_cart_type=Wishlist'

  return this.http.get<Cart>(`${this.url}${queryString}`).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public createWishlistGroup(requestpayload): Observable<any> {
  this.url= this.config.CREATE_WISH_LIST_GROUP.url;
  requestpayload['customer_id'] = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');

  return this.http.post<any>(this.url,requestpayload).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public removeWishlistGroup(id): Observable<any> {
  this.url= this.config.CREATE_WISH_LIST_GROUP.url;
  let queryParams = new HttpParams();
  queryParams = queryParams.append('groupId',id);

  return this.http.delete<any>(this.url,{params : queryParams}).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public getMyWishListGroup(): Observable<any> {
  this.url=this.config.GET_MY_WISH_LIST_GROUP.url;
  let queryString : string = '?customer_id='+`${CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id')}`
  +'&page_index=0'
  +'&page_size=100'
  return this.http.get<any>(`${this.url}${queryString}`).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public addWishlist(requestpayload): Observable<any> {
  this.url= this.config.ADD_WISH_LIST_TO_COLLECTION.url;
  requestpayload['customer_id'] = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');
  requestpayload['store_id'] = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id');

  return this.http.post<any>(this.url,requestpayload).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
public addCollectionToCart(requestpayload):Observable<any> {
  this.url= this.config.ADD_WISH_LIST_COLLECTION_TO_CART.url;
  requestpayload['customer_id'] = CommonUtil.getStoreIdFromLocalStorageUserProfile('customer_id');
  requestpayload['store_id'] = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id');

  return this.http.post<any>(this.url,requestpayload).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => of(httpErrorResponse.error))
  );
}
}
