import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

// Header and Footer Components

// Components
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductBoxOneComponent } from './components/product/product-box-one/product-box-one.component';
import { ProductBoxTwoComponent } from './components/product/product-box-two/product-box-two.component';
import { ProductBoxThreeComponent } from './components/product/product-box-three/product-box-three.component';
import { ProductBoxFourComponent } from './components/product/product-box-four/product-box-four.component';
import { ProductBoxFiveComponent } from './components/product/product-box-five/product-box-five.component';
import { ProductBoxVerticalComponent } from './components/product/product-box-vertical/product-box-vertical.component';
import { ProductBoxVerticalSliderComponent } from './components/product/product-box-vertical-slider/product-box-vertical-slider.component';

// Modals Components
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';
import { VideoModalComponent } from './components/modal/video-modal/video-modal.component';
import { SizeModalComponent } from './components/modal/size-modal/size-modal.component';
import { AgeVerificationComponent } from './components/modal/age-verification/age-verification.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
// Skeleton Loader Components
import { SkeletonProductBoxComponent } from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Layout Box
import { LayoutBoxComponent } from './components/layout-box/layout-box.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';
import { FooterFiveComponent } from './footer/footer-five/footer-five.component';
import { HeaderFiveComponent } from './header/header-five/header-five.component';
import { CategoryComponent } from './components/category/category.component';
import { ProductCardComponent } from './components/product/product-card/product-card.component';
import { MultiItemSliderComponent } from './components/multi-item-slider/multi-item-slider.component';
import { BrandSliderComponent } from './components/brand-slider/brand-slider.component';
import { ProductBrandBoxComponent } from './components/product/product-brand-box/product-brand-box.component';
import { CategoryWidgetComponent } from './components/category-widget/category-widget.component';
import { CategorySubMenuComponent } from './components/category-sub-menu/category-sub-menu.component';
import { CategoryBannerComponent } from './components/category-banner/category-banner.component';
import { AdsContainerComponent } from '../home/widgets/ads-container/ads-container.component';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ToggleMenuComponent } from './components/toggle-menu/toggle-menu.component';
import {  MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { ProductCardTabComponent } from './components/product/product-card-tab/product-card-tab.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CUSTOMER_PORTAL_CONSTANT } from '../constant/constant';
import {MatRadioModule} from '@angular/material/radio';
import { SliderComponent } from './components/slider/slider.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { SubCategoryComponent } from './components/sub-category/sub-category.component';
import { MatTableModule } from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { StaticbreadcrumbComponent } from './components/staticbreadcrumb/staticbreadcrumb.component';
import { NestedMegamenuComponent } from './components/nested-megamenu/nested-megamenu.component';
import { MenuContainerComponent } from './components/menu-container/menu-container.component';
import {MatMenuModule} from '@angular/material/menu';
import { CountdownComponent } from '../shop/product/widgets/countdown/countdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CharacterValidationDirective } from './directive/character-validation.directive';

export const HTTP_LOADER_FACTORY: (httpClient: HttpClient) =>
    TranslateHttpLoader = (httpClient: HttpClient): any => new TranslateHttpLoader(httpClient,'./assets/i18n/','.json');
    
@NgModule({
  declarations: [
    FooterFiveComponent,
    HeaderFiveComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    StaticbreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    FooterFiveComponent,
    HeaderFiveComponent,
    CategoryComponent,
    ProductCardComponent,
    MultiItemSliderComponent,
    BrandSliderComponent,
    ProductBrandBoxComponent,
    CategoryWidgetComponent,
    CategorySubMenuComponent,
    CategoryBannerComponent,
    AdsContainerComponent,
    ProfileMenuComponent,
    ToggleMenuComponent,
    ProductCardTabComponent,
    SliderComponent,
    SubCategoryComponent,
    NestedMegamenuComponent,
    MenuContainerComponent,
    CountdownComponent,
    CharacterValidationDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    MatTableModule,
    AutocompleteLibModule,
    Ng2TelInputModule,
    MatMenuModule,
    NgSelectModule,
    LazyLoadImageModule.forRoot({
      // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    NgxSkeletonLoaderModule,
    MatSidenavModule,MatFormFieldModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    NgOtpInputModule,
    TranslateModule.forChild(
      {
          loader: {
              provide: TranslateLoader,
              useFactory: HTTP_LOADER_FACTORY,
              deps: [HttpClient]
          }
      }
  )
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    MatTableModule,
    FooterFiveComponent,
    HeaderFiveComponent,
    BreadcrumbComponent,
    StaticbreadcrumbComponent,
    CategorySubMenuComponent,
    CategoriesComponent,
    CategoryWidgetComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    MultiItemSliderComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    BrandSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    CategoryComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    AdsContainerComponent,
    ProductCardComponent,
    ProfileMenuComponent,
    ToggleMenuComponent,
    ProductCardTabComponent,
    Ng2TelInputModule,
    NgOtpInputModule,
    MatRadioModule,
    MatIconModule,
    MatDatepickerModule,MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    SliderComponent,
    SubCategoryComponent,
    MatMenuModule,
    MenuContainerComponent,
    CountdownComponent,
    NgSelectModule,
    CharacterValidationDirective
  ]
})
export class SharedModule {
  constructor(private translateService: TranslateService) {
    const currentLang: string = this.translateService.currentLang || CUSTOMER_PORTAL_CONSTANT.DEFAULT_LANGUAGE;
    this.translateService.use(currentLang);
  }
}
